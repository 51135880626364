<template>
    <div class="loader-typing">
      <div class="chat__dialog-content loader">
        <ul class="d-flex justify-center align-center loader-typing__dots">
          <li class="loader-typing__dot"></li>
          <li class="loader-typing__dot"></li>
          <li class="loader-typing__dot"></li>
        </ul>
      </div>
    </div>
</template>

<script>
    export default {
        name: "vLoaderIsTyping",
    }
</script>

<style lang="scss" src="@/assets/scss/loader.scss"></style>