<template>
    <div class="d-flex flex-column chat__dialog-message" :class="{ 'align-end': message.me, 'align-start': !message.me, }">
        <div class="d-flex flex-column chat__dialog-content" :class="{ 'me': message.me, }">
          <div class="chat__dialog-main-content">
           <div 
            v-if="!message.image_url" 
            class="d-flex flex-column dialog__user" 
            v-html="message.me ? message.content : parseMarkdown(message.content, index)"
            @click="clickByBtnCopyCode($event)"
          ></div>
             <div 
               v-else 
               style="background-color: transparent; border-radius: none;"
               :class="{ 'me': message.me }"
             >
               <img class="dialog__image blur" :src="message.image_url" alt="" @load="loadResImage($event)">
             </div>
          </div>
          <div class="mt-2 d-flex flex-column align-end chat__dialog-files" v-if="message.uploadFile">
            <div class="d-flex align-center upload-file-card">
              <div class="upload-file-card__name">{{ message.uploadFile.name }}</div>
            </div>
          </div>
         </div>
         <div class="chat__dialog-message-controls">
          <button 
            v-if="!message.image_url"
            class="d-flex justify-center align-center mt-1 chat__dialog-message-control" 
            @click="doCopy(message.content, index, message.isCopied)"
          >
            <Tippy 
              :content="message.isCopied ? 'Скопировано' : 'Копировать'" 
              :theme="theme" 
              v-tippy="tippyOptions" 
            >
              <svg v-if="!message.isCopied" class="icon-stroke icon-stroke-active" width="24" height="24" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.94605 4.99995L13.2541 4.99995C14.173 5.00498 15.0524 5.37487 15.6986 6.02825C16.3449 6.68163 16.7051 7.56497 16.7001 8.48395V12.716C16.7051 13.6349 16.3449 14.5183 15.6986 15.1717C15.0524 15.825 14.173 16.1949 13.2541 16.2H8.94605C8.02707 16.1949 7.14773 15.825 6.50148 15.1717C5.85522 14.5183 5.495 13.6349 5.50005 12.716L5.50005 8.48495C5.49473 7.5658 5.85484 6.6822 6.50112 6.0286C7.1474 5.375 8.0269 5.00498 8.94605 4.99995Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.1671 19H14.9371C17.4857 18.9709 19.5284 16.8816 19.5001 14.333V9.666" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg v-else class="icon-fill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25074 10.2496C6.58548 9.91489 7.37986 9.91748 7.7146 10.2522L10.75 13.2499L16.75 7.24987C17.0847 6.91513 17.9153 6.91748 18.25 7.25221C18.5847 7.58695 18.5847 8.41513 18.25 8.74987L12.25 14.7499L10.75 16.2499C10.75 16.2499 11.0861 16.5858 10.7513 16.2511C10.4166 15.9164 9.25 14.7499 9.25 14.7499L6.25136 11.751C5.91663 11.4162 5.91601 10.5844 6.25074 10.2496Z" fill="#7B7D82"/>
              </svg>
            </Tippy>
          </button>
          <button 
            v-else
            class="d-flex justify-center align-center mt-1 chat__dialog-message-control" 
            @click="downloadImage(index, message.image_url, message.imageDownloaded)"
          >
            <Tippy 
              :content="message.imageDownloaded ? 'Скачано' : 'Скачать изображение'" 
              :theme="theme" 
              v-tippy="tippyOptions" 
            >
              <svg v-if="!message.imageDownloaded" class="icon-fill icon-fill-active" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9993 11.9697C13.9992 11.9698 13.9994 11.9696 13.9993 11.9697C13.9964 11.9726 13 12.969 13 12.4697L13.0002 5.96973C13.0002 5.96973 13.0002 5.96973 13.0002 5.96973C13.0002 5.96965 13.0001 4.96973 12.0002 4.96973C11.0002 4.96973 11.0002 5.96964 11.0002 5.96973C11.0002 5.96973 11.0002 5.96973 11.0002 5.96973L11 12.4697C10.9999 12.9694 10.0012 11.9712 9.99971 11.9697C9.99966 11.9697 9.99975 11.9698 9.9997 11.9697C9.99822 11.9682 9.49948 11.4705 8.99931 11.9697L8.49889 12.4696C8.49879 12.4697 8.49882 12.4696 8.49872 12.4697C8.4954 12.4731 8.00129 12.9714 8.50023 13.4697C11.1109 16.0804 11.4497 16.4192 11.8232 16.4632C11.8789 16.4697 11.9353 16.4697 12.0002 16.4697C12.5002 16.4697 12.502 16.4697 15.4993 13.4697C15.9988 12.9698 15.4994 12.4698 15.4993 12.4697C15.4993 12.4697 15.4993 12.4697 15.4993 12.4697L14.9997 11.9697C14.5017 11.4712 14.0022 11.9668 13.9993 11.9697ZM17.5 17.4697V14.9697C17.5 14.9697 17.5 13.9697 18.5 13.9697C19.5 13.9697 19.5 14.9697 19.5 14.9697V18.9697C19.5 19.9697 18.5 20.9697 17.5 20.9697H6.5C5.5 20.9697 4.5 19.9697 4.5 18.9697V14.9697C4.5 14.9697 4.5 13.9697 5.5 13.9697C6.5 13.9697 6.5 14.9697 6.5 14.9697V17.4697C6.5 17.4697 6.5 17.9697 7 18.4697C7.5 18.9697 8 18.9697 8 18.9697H16C16 18.9697 16.5 18.9697 17 18.4697C17.5 17.9697 17.5 17.4697 17.5 17.4697Z" fill="#797A7E"/>
              </svg>
              <svg v-else class="icon-fill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25074 10.2496C6.58548 9.91489 7.37986 9.91748 7.7146 10.2522L10.75 13.2499L16.75 7.24987C17.0847 6.91513 17.9153 6.91748 18.25 7.25221C18.5847 7.58695 18.5847 8.41513 18.25 8.74987L12.25 14.7499L10.75 16.2499C10.75 16.2499 11.0861 16.5858 10.7513 16.2511C10.4166 15.9164 9.25 14.7499 9.25 14.7499L6.25136 11.751C5.91663 11.4162 5.91601 10.5844 6.25074 10.2496Z" fill="#7B7D82"/>
              </svg>
            </Tippy>
          </button>
         </div>
    </div>
</template>

<script>
import hljs from "highlight.js";
import useClipboard from "vue-clipboard3";
import { markedHighlight, } from "marked-highlight";
import { Marked, } from "marked";
import { Tippy, } from "vue-tippy";
import { ref, inject, } from "vue";
import { useStore, } from "vuex";

export default {
    name: "vCardMessage",
    setup(props, { emit, }) {
        const AIisTyping = inject("AIisTyping");
        const store = useStore();
        const delay = ref(3000);
        const { toClipboard, } = useClipboard();
        const tippyOptions = {
            animation : "shift-away",
            trigger: "mouseenter mousedown",
            size: "small",
            placement: "bottom",
            touch: true,
        };

        const loadResImage = (e) => {
            const img = e.currentTarget;

            e.currentTarget.classList.remove("blur");

            e.currentTarget.style.width = `${img.naturalWidth}px`;
            e.currentTarget.style.height = "auto";

            emit("imageIsLoaded");
        };
        const downloadImage = async (index, imageUrl, alreadyClicked) => {
            if (alreadyClicked) {
                return;
            }

            const image = await fetch(imageUrl);
            const blob = await image.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");

            link.href = url;
            link.download = "image";
            link.click();

            store.commit("editMessageInActiveChat", { key: "imageDownloaded", value: true, index, });

            new Promise((res) => {
                setTimeout(res, delay.value);
            }).then(() => {
                store.commit("editMessageInActiveChat", { key: "imageDownloaded", value: false, index, });
            });
        };
        const parseTextFromMarkDown = (mdString) => {
            const htmlString = new Marked().parse(mdString);
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");
            const walker = document.createTreeWalker(doc, NodeFilter.SHOW_TEXT);
            const textList = [];

            let currentNode = walker.currentNode;

            while(currentNode) {
                textList.push(currentNode.textContent);
                currentNode = walker.nextNode();
            }

            return textList.filter(Boolean).join("").replace(/\n$/, "");
        };
        const doCopy = (msg, index, alreadyClicked) => {
            if (alreadyClicked) {
                return;
            }

            toClipboard(parseTextFromMarkDown(msg))
                .then(() => {
                    store.commit("editMessageInActiveChat", { key: "isCopied", value: true, index, });

                    new Promise((res) => {
                        setTimeout(res, delay.value);
                    }).then(() => {
                        store.commit("editMessageInActiveChat", { key: "isCopied", value: false, index, });
                    }).catch((err) => {
                        console.error(err);
                    });
                }).catch((err) => {
                    console.error(err);
                });
        };
        const changeBtnCopyState = (strKey) => {
            const isCopied = props.codeCopies.get(strKey);
            const [msgIdx, codeIdx] = strKey.split("-");
            const pre = document.querySelector(`pre[data-msg="${msgIdx}"][data-code="${codeIdx}"]`);
            const isCopiedIcon = pre.querySelector(".is-copied-icon");
            const isNotCopiedIcon = pre.querySelector(".is-not-copied-icon");
            const btnText = pre.querySelector(".chat__dialog-message-code-copy-text");
            const btn = pre.querySelector(".chat__dialog-message-code-copy");

            isCopiedIcon.classList[isCopied ? "remove" : "add"]("d-none");
            isNotCopiedIcon.classList[isCopied ? "add" : "remove"]("d-none");
            btnText.innerText = isCopied ? "Скопировано!" : "Копировать код";
            btn.classList[isCopied ? "remove" : "add"]("is-not-copied");
        };
        const getStrKey = (target) => {
            const parent = target.closest("pre");
            
            return `${parent.dataset.msg}-${parent.dataset.code}`;
        };
        const clickByBtnCopyCode = (e) => {
            const target = e.target;

            if (!target.closest(".chat__dialog-message-code-copy")) {
                return;
            }

            const codeEl = target.closest("pre").querySelector("code");
            const strKey = getStrKey(target);

            if (props.codeCopies.get(strKey)) {
                return;
            }

            emit("setCodeCopies", { key: strKey, value: true, })

            toClipboard(codeEl.innerText.replace(/\n$/, ""));
            changeBtnCopyState(strKey);

            setTimeout(() => {
                emit("setCodeCopies", { key: strKey, value: false, })

                changeBtnCopyState(strKey);
            }, delay.value);
        };
        const addCodeHeader = (htmlStr, msgIndex) => {
            const el = document.createElement("div");

            el.innerHTML = htmlStr;

            const code = el.querySelectorAll("code.hljs");

            code.forEach((codeEl, codeIndex) => {
                const parentCodeEl = codeEl.closest("pre");
                const lang = (codeEl.className.match(/(?<=languge-).+\b/) || [])[0] || "Код";
                const pre = codeEl.closest("pre");

                const headerHTMLContent = `
                <header class="d-flex align-center justify-space-between chat__dialog-message-code-header">
                    <span class="mr-2 chat__dialog-message-code-lang">${lang}</span>
                    <button class="d-flex justify-end align-center is-not-copied chat__dialog-message-code-copy">
                        <svg class="chat__dialog-message-code-copy-icon is-not-copied-icon icon-stroke" width="24" height="24" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.94605 4.99995L13.2541 4.99995C14.173 5.00498 15.0524 5.37487 15.6986 6.02825C16.3449 6.68163 16.7051 7.56497 16.7001 8.48395V12.716C16.7051 13.6349 16.3449 14.5183 15.6986 15.1717C15.0524 15.825 14.173 16.1949 13.2541 16.2H8.94605C8.02707 16.1949 7.14773 15.825 6.50148 15.1717C5.85522 14.5183 5.495 13.6349 5.50005 12.716L5.50005 8.48495C5.49473 7.5658 5.85484 6.6822 6.50112 6.0286C7.1474 5.375 8.0269 5.00498 8.94605 4.99995Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.1671 19H14.9371C17.4857 18.9709 19.5284 16.8816 19.5001 14.333V9.666" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg class="chat__dialog-message-code-copy-icon d-none is-copied-icon icon-fill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25074 10.2496C6.58548 9.91489 7.37986 9.91748 7.7146 10.2522L10.75 13.2499L16.75 7.24987C17.0847 6.91513 17.9153 6.91748 18.25 7.25221C18.5847 7.58695 18.5847 8.41513 18.25 8.74987L12.25 14.7499L10.75 16.2499C10.75 16.2499 11.0861 16.5858 10.7513 16.2511C10.4166 15.9164 9.25 14.7499 9.25 14.7499L6.25136 11.751C5.91663 11.4162 5.91601 10.5844 6.25074 10.2496Z" fill="#7B7D82"/>
                        </svg>
                        <span class="ml-1 chat__dialog-message-code-copy-text">Копировать код</span>
                    </button>
                </header>
                `;

                parentCodeEl.className += "d-flex flex-column";
                parentCodeEl.insertAdjacentHTML("afterbegin", headerHTMLContent);

                pre.dataset.msg = msgIndex;
                pre.dataset.code = codeIndex;
            });

            return el.innerHTML;
        };
        const parseMarkdown = (md, msgIndex) => {
            const postprocess = (htmlStr) => addCodeHeader(htmlStr, msgIndex);

            return new Marked(markedHighlight({
                langPrefix: "hljs languge-",
                highlight(code, lang) {
                    const language = hljs.getLanguage(lang) ? lang : "plaintext";

                    return hljs.highlight(code, { language, }).value;
                },
            })).use({ hooks: { postprocess, }, }).parse(md);
        };

        return {
            parseMarkdown,
            clickByBtnCopyCode,
            doCopy,
            downloadImage,
            loadResImage,
            tippyOptions,
            AIisTyping,
        };
    },
    props: {
        message: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
        },
        codeCopies: {
            type: Map,
            default: new Map(),
        },
        theme: {
            type: String,
            default: "light",
        },
    },
    emit: ["setCodeCopies", "imageIsLoaded"],
    components: {
        Tippy,
    },
}
</script>

<style lang="scss" src="@/assets/scss/cardMessage.scss"></style>