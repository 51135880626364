<template>
    <div class="d-flex h-screen main-wrapper">
      <vMenu />
      <main class="flex-grow-1 main">
        <div class="main__content">
          <router-view
            @dragFileEnter="showDropArea = true"
            @setFile="setFile"
            :uploadFile="droppedFile"
          />
        </div>
      </main>
      <Transition name="fade">
        <vDropFileArea v-if="showDropArea" @leave="showDropArea = false" @dropFile="setFile" />
      </Transition>
    </div>
  </template>
  
  <script>
    import vDropFileArea from "@/components/vDropFileArea.vue";
    import vMenu from "@/components/vMenuBlockComponent.vue";
    import getValidExtensions from "@/composables/validExtensions";
    import { ref, onMounted, inject, provide, computed, } from "vue";
    import { useStore, } from "vuex";

    export default {
      name: "vMainTemplate",
      setup() {
        const store = useStore();

        const $cookies = inject("$cookies");
        const showDropArea = ref(false);
        const droppedFile = ref(null);

        const getActiveChat = computed(() => store.getters.getActiveChat);
        const getActiveAIModel = computed(() => store.getters.getActiveAI);

        provide("showSideMenu", ref(false));
        provide("AIisTyping", ref(false));
        provide("aiVersion", computed(() => {
          if (getActiveChat.value === "image") {
            return "dall-e-3";
          }

          return getActiveAIModel.value;
        }));
        provide("validExtensions", ref(getValidExtensions()));

        const setFile = (val) => {
          showDropArea.value = false;
          droppedFile.value = val;
        };

        onMounted(() => {
          if (!$cookies.isKey("username")) {
            return;
          }

          const username = $cookies.get("username");

          store.commit("setUserData", { key: "username", val: username, });
        });

        return { setFile, showDropArea, droppedFile, };
      },
      components: {
        vMenu,
        vDropFileArea,
      },
    }
  </script>
  
<style scoped lang="scss" src="@/assets/scss/main.scss"></style>