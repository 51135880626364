<template>
    <button 
        class="d-flex align-center justify-center chat-wrapper__control-btn" 
        :class="{ 'small': !cleanDialogBtnIsActive && !isMobile }"
        :disabled="!getActiveChatMessages.length"
        @mouseenter="setCleanDialogBtnIsActive(true)" 
        @mouseleave="setCleanDialogBtnIsActive(false)"
    >
        <span class="chat-wrapper__control-text" v-if="isMobile || cleanDialogBtnIsActive">Стереть</span>
        <svg class="icon-fill icon-fill-active" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.90332 18.9678H21.0003V20.1291H5.90332V18.9678Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0136 3.87109C13.7119 3.87633 13.4241 3.99878 13.2111 4.21252L3.34001 14.0836C3.1223 14.3014 3 14.5967 3 14.9047C3 15.2126 3.1223 15.5079 3.34001 15.7257L5.42224 17.8068H13.3516L20.0791 11.0793C20.2968 10.8615 20.4191 10.5662 20.4191 10.2583C20.4191 9.95034 20.2968 9.65501 20.0791 9.43724L14.8532 4.21136C14.7431 4.10148 14.612 4.01485 14.4678 3.9566C14.3236 3.89835 14.1691 3.86967 14.0136 3.87226V3.87109ZM9.85262 10.7228L13.4956 14.317C13.4956 14.317 14.0763 14.8977 13.4956 15.4784L12.3599 16.6455H6.36522L4.52571 14.9035L8.69131 10.7216C9.27197 10.141 9.85262 10.7228 9.85262 10.7228Z" fill="white"/>
        </svg>
    </button>
</template>

<script>
import { useStore, } from "vuex";
import { computed, } from "vue";

export default {
    name: "vClearHistoryBtn",
    setup: (props, { emit, }) => {
        const store = useStore();
        const getActiveChatMessages = computed(() => store.getters.getActiveChatMessages);
        const setCleanDialogBtnIsActive = (val) => {
            if (!getActiveChatMessages.value.length || props.isMobile) {
                return;
            }

            emit("setCleanDialogBtnIsActive", val);
        }

        return {
            setCleanDialogBtnIsActive,
            getActiveChatMessages,
        };
    },
    props: {
        cleanDialogBtnIsActive: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    emit: ["setCleanDialogBtnIsActive"]
};
</script>