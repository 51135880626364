<template>
  <div class="app-content" :data-theme="theme">
    <router-view/>
  </div>
</template>

<script>
  import { onMounted, ref, watch, provide, } from "vue";

  export default {
    name: "mainApp",
    setup() {
      const theme = ref(localStorage.getItem("theme") || "light");
      const variablesCss = {
        "--viewport-height": () => `${window.innerHeight}px`,
        "--dark-theme-black": "#191C1E",
        "--light-theme-white": "#E3E6ED",
      };

      provide("theme", theme);

      const setViewportHeight = () => {
        Object.keys(variablesCss).forEach((varName) => {
          const varValue = variablesCss[varName];

          document.documentElement.style.setProperty(varName, varValue instanceof Function ? varValue() : varValue);
        });
      };
      const addThemeMetaTag = () => {
        const headEl = document.querySelector("head");
        const findMetaTag = headEl.querySelector("meta[name=theme-color]");
        const metaTag = findMetaTag ? findMetaTag : document.createElement("meta");
        const color = variablesCss[theme.value === "light" ? "--light-theme-white" : "--dark-theme-black"];

        metaTag.name = "theme-color";
        metaTag.content = color;

        if (!findMetaTag) {
          headEl.appendChild(metaTag);
        }
      };

      onMounted(() => {
        setViewportHeight();
        addThemeMetaTag();

        window.addEventListener("resize", setViewportHeight);
      });

      watch(() => theme, (val) => {
        localStorage.setItem("theme", val.value);
        
        addThemeMetaTag();
      }, { deep: true, });

      return { theme, };
    },
  }
</script>

<style lang="scss" src="@/assets/scss/global.scss"></style>