<template>
  <div class="chat-wrapper">
    <div class="chat-wrapper__row chat-wrapper__row--header">
      <vHeader
        :showChatControls="showChatControls"
        @setShowChatControls="setShowChatControls"
      />
    </div>
    <div class="chat-wrapper__row chat-wrapper__row--chat" @dragenter="onDragEnter($event)">
      <PerfectScrollbar ref="elScrollbar" @ps-scroll-down="setSettingsChatControls" @ps-scroll-up="setSettingsChatControls">
        <div class="chat">
          <h3 v-if="!getActiveChatMessages.length" class="chat__start-text">Начните работу</h3>
          <div v-else class="chat__wrapper">
            <div class="d-flex flex-column ga-2 chat__dialog">
              <vCardMessage
                v-for="(msg, index) in getActiveChatMessages"
                :key="index"
                :index="index"
                :message="msg"
                :codeCopies="codeCopies"
                :theme="theme"
                @imageIsLoaded="scrollChatTo"
                @setCodeCopies="setCodeCopies"
              />
              <vLoaderIsTyping v-if="AIisTyping" />
            </div>
          </div>
        </div>
      </PerfectScrollbar>
      <div class="d-flex align-center chat-wrapper__controls">
        <vClearHistoryBtn
          :cleanDialogBtnIsActive="cleanDialogBtnIsActive"
          :isMobile="isMobile"
          @click="clearHistory"
          @setCleanDialogBtnIsActive="setCleanDialogBtnIsActive"
        />
      </div>
    </div>
    <div class="chat-wrapper__row chat-wrapper__row--input">
      <vInputChatComponent :file="uploadFile" @newMessage="newMessage" @setFile="setFile" />
    </div>
  </div>
</template>

<script>
import vInputChatComponent from "@/components/vInputChatComponent.vue";
import vHeader from "@/components/vHeaderSettingsChatComponent.vue";
import vClearHistoryBtn from "@/components/vClearHistoryBtn.vue";
import vCardMessage from "@/components/vCardMessage.vue";
import vLoaderIsTyping from "@/components/vLoaderIsTyping.vue";
import isMobileComposable from "@/composables/isMobile.js";
import { computed, watch, ref, inject, nextTick, } from "vue";
import { useStore, } from "vuex";

export default {
  setup(props, { emit, }) {
    const store = useStore();

    const { isMobile, } = isMobileComposable();

    const axios = inject("axios");
    const AIisTyping = inject("AIisTyping");
    const theme = inject("theme");

    const elScrollbar = ref(null);
    const cleanDialogBtnIsActive = ref(false);
    const showChatControls = ref(true);
    const codeCopies = ref(new Map());

    const getActiveChatMessages = computed(() => store.getters.getActiveChatMessages);
    const getActiveChat = computed(() => store.getters.getActiveChat);
    const getActiveAI = computed(() => store.getters.getActiveAI);

    const setCodeCopies = ({ key, value, }) => codeCopies.value.set(key, value);
    const setCleanDialogBtnIsActive = (val) => cleanDialogBtnIsActive.value = val;
    const setShowChatControls = (val) => showChatControls.value = val;
    const setFile = (file) => emit("setFile", file);
    const displayLocalMessages = () => {
      const localMessages = JSON.parse(localStorage.getItem("userMessages") || "[]");

      localMessages.forEach((message) => {
        const { chat, } = message;
        
        store.commit("addMessageToActiveChat", { message, chat, });
      });
    };
    const onDragEnter = (e) => {
      if (!e.target.closest(".chat-wrapper__row--chat") || getActiveChat.value === "image") {
        return;
      }

      emit("dragFileEnter");
    };

    const setSettingsChatControls = (e) => {
      if (!isMobile.value) {
        return;
      }

      showChatControls.value = e.type === "ps-scroll-down" ? false : true;
    };
    const scrollChatTo = async (bottom = true) => {
      await nextTick();

      if (!elScrollbar.value) {
        return;
      }
      
      const element = document.querySelector(".ps");

      element.scrollTop = bottom ? element.scrollHeight : 0;
    };
    const newMessage = (data) => {
      if (AIisTyping.value) {
        return;
      }

      const fileIsLoad = getActiveAI.value === "gpt-4o" && getActiveChat.value !== "image";
      const file = props.uploadFile ? { name: props.uploadFile.name, } : null;
      const { message, } = data;
      const fd = new FormData();

      Object.keys(data).filter((key) => {
        if (!fileIsLoad && key !== "file") {
          return key;
        } else if (fileIsLoad) {
          return key;
        }
      }).forEach((key) => fd.append(key, data[key]));

      // Добавляем сообщение пользователя
      store.commit("addMessageToActiveChat", {
        message: {
          content: message,
          me: true,
          uploadFile: fileIsLoad ? file : null,
          chat: getActiveChat.value,
        },
      });

      AIisTyping.value = true;

      scrollChatTo();

      axios.post("/chat_api/", fd, { headers: { "Content-Type": "multipart/form-data", }, })
        .then((res) => {
          const { message, } = res.data;
          const messageIsString = typeof message === "string";

          // Добавляем ответ ИИ
          store.commit("addMessageToActiveChat", {
            message: {
              content: messageIsString ? message : null,
              me: false,
              image_url: !messageIsString ? message.image_url : null,
              chat: getActiveChat.value,
            },
          });
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          AIisTyping.value = false;

          scrollChatTo();
        });
    };
    const clearHistory = () => {
      if (!getActiveChatMessages.value.length) {
        return;
      }

      axios.post(`/chat_api/clear?chat=${getActiveChat.value}`)
        .then((res) => {
          const { status, } = res.data;

          if (status === "1") {
            const chats = store.state.chats;

            store.commit("clearActiveChat");

            const arrMessages = Object
              .keys(chats)
              .reduce((acc, key) => {
                acc.push(...chats[key]);
                return acc;
              }, []);

            cleanDialogBtnIsActive.value = false;

            localStorage.setItem("userMessages", JSON.stringify(arrMessages));

            scrollChatTo(false);
          }
        }).catch((err) => {
            console.error(err);
        });
    };

    watch(() => getActiveChat.value, () => {
      scrollChatTo(false);

      codeCopies.value.forEach((_, key) => {
        codeCopies.value.set(key, false);
      });

      store.commit("clearAllChats");

      displayLocalMessages();
    }, { immediate: true, });

    return {
      setFile,
      onDragEnter,
      setCleanDialogBtnIsActive,
      setSettingsChatControls,
      newMessage,
      setShowChatControls,
      clearHistory,
      setCodeCopies,
      scrollChatTo,
      isMobile,
      AIisTyping,
      getActiveChatMessages,
      theme,
      codeCopies,
      cleanDialogBtnIsActive,
      showChatControls,
      elScrollbar,
    };
  },
  name: "vChatComponent",
  emit: ["setFile", "dragFileEnter"],
  props: {
    uploadFile: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    vInputChatComponent,
    vHeader,
    vClearHistoryBtn,
    vCardMessage,
    vLoaderIsTyping,
  },
}
</script>

<style lang="scss" src="@/assets/scss/chat.scss"></style>
