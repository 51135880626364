export default function getValidExtensions() {
    return [
        {
            name: ".docx",
            value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        },
        {
            name: ".doc",
            value: "application/msword",
        },
        {
            name: ".dot",
            value: "application/msword",
        },
        {
            name: ".docm",
            value: "application/vnd.ms-word.document.macroEnabled.12",
        },
        {
            name: ".dotx",
            value: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
        },
        {
            name: ".dotm",
            value: "application/vnd.ms-word.template.macroEnabled.12",
        },
        {
            name: ".rtf",
            value: "application/rtf",
        },
        {
            name: ".txt",
            value: "text/plain",
        },
        {
            name: ".text",
            value: "text/plain",
        },
        {
            name: ".text",
            value: "text/plain",
        },
        {
            name: ".pdf",
            value: "application/pdf",
        },
        {
            name: ".log",
            value: "text/plain",
        },
        {
            name: ".conf",
            value: "text/plain",
        },
        {
            name: ".csv",
            value: "text/csv",
        },
        {
            name: ".xlsx",
            value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        {
            name: ".xls",
            value: "application/vnd.ms-excel",
        },
        {
            name: ".mp3",
            value: "audio/mpeg",
        },
        {
            name: ".wav",
            value: "audio/wav",
        },
        {
            name: ".aac",
            value: "audio/aac",
        },
        {
            name: ".flac",
            value: "audio/flac",
        },
        {
            name: ".ogg",
            value: "audio/ogg",
        },
        {
            name: ".wma",
            value: "audio/x-ms-wma",
        },
        {
            name: ".m4a",
            value: "audio/mp4",
        },
        {
            name: ".aiff",
            value: "audio/aiff",
        },
        {
            name: ".aif",
            value: "audio/aiff",
        },
        {
            name: ".opus",
            value: "audio/opus",
        }
    ];
}