<template>
    <div class="drop-file-area" @click="close($event)">
        <div 
            class="drop-file-area__wrapper" 
            @dragleave="onDragLeave($event)"
            @dragover.prevent
            @drop.prevent="onDrop($event)"
        >
            <div class="d-flex justify-center flex-column align-center text-center drop-file-area__content">
                <h3 class="mb-2 drop-file-area__title">{{ title }}</h3>
                <p class="drop-file-area__desc" v-html="desc"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, inject, } from "vue";

export default {
    setup(props, { emit, }) {
        const validExtensions = inject("validExtensions");
        const title = ref("");
        const desc = ref("");

        const close = (e) => {
            if (!e.target.classList.contains("drop-file-area")) {
                return;
            }

            emit("leave");
        };
        const onDrop = (e) => {
            const files = e.dataTransfer.files;

            if (!files.length) {
                title.value = "Внимание";
                desc.value = "можно прикреплять только файлы!";

                return;
            } else if (files.length > 1) {
                title.value = "Внимание";
                desc.value = "можно прикрепить только 1 файл!";

                return;
            }

            const [file] = files;

            if (!validExtensions.value.map(({ value, }) => value).includes(file.type)) {
                const strExtensions = validExtensions.value.map(({ name, }) => `<b>${name}</b>`);

                title.value = "Внимание";
                desc.value = `можно прикрепить файл только с указанным расширением: ${strExtensions.join(", ")}`;

                return;
            }

            title.value = "Успешно";
            desc.value = "файл был прикреплен";

            emit("dropFile", file);
        };
        const onDragLeave = (e) => {
            if (!e.target.classList.contains("drop-file-area__wrapper")) {
                return;
            }
            
            emit("leave");
        };

        onMounted(() => {
            title.value = "Перетащите сюда файл";
            desc.value = "для его последующей отправки";
        });

        return {
            close,
            onDrop,
            onDragLeave,
            title,
            desc,
        };
    },
    emit: ["leave", "dropFile"],
    name: "vDropFileArea",
}
</script>

<style scoped lang="scss" src="@/assets/scss/dropFileArea.scss"></style>